<template>
  <div>
    <div class="bg-link-water p-3 rounded">
      <div class="flex justify-between flex-wrap justify-sm-center justify-xs-center">
        <div class="m-1">
          <span class="text-primary text-uppercase font-bold text-2xl ml-2"
            >Communication</span
          >
          <span class="text-uppercase ml-2 text-gray-600 font-bold"
            >( Total 75 News )</span
          >
        </div>
        <div class="flex justify-end m-1">
          <SelectComponent border-none class="bg-periwinkle-gray rounded w-64 " />
        </div>
      </div>
    </div>

    <STCommunication
      v-for="i in 4"
      :key="i"
      @click="dialog(true)"
    />
    <div class="flex justify-end align-center my-6">
        <Paginate
        :start="1"
        :end="20"
        :total="100"
        :limit="20"
        />
      </div>
    <!-- Dialog Start -->
    <Dialog>
      <StCommunicationDetails />
    </Dialog>
    <!-- Dialog Start -->
  </div>
</template>

<script>
import SelectComponent from "@/components/atom/form/SelectComponent";
import STCommunication from "@/components/molecule/communication/STCommunication";
import StCommunicationDetails from "@/components/molecule/communication/StCommunicationDetails";
import { Dialog, Paginate } from "@/components";
import { mapMutations } from "vuex";

export default {
  components: {
    Dialog,
    Paginate,
    SelectComponent,
    STCommunication,
    StCommunicationDetails
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
  },
};
</script>

<style></style>
